
    import VuePrismEditor from "vue-prism-editor";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            VuePrismEditor
        },
    })

    // *****************************************************************************************************************
    export default class DevHttp extends Vue {
        // Data function
        public data() {
            return {
                systemsIconPathAndName:
                    require("@/assets/images/icon_systems.png"),

                diagramOfHttp:
                    '            userinfo          host        port' + '\n' +
                    '        ┌───────┴───────┐ ┌────┴────────┐ ┌┴┐' + '\n' +
                    ' http://john.doe:password@www.example.com:123/questions/?tag=networking&order=newest#top' + '\n' +
                    ' └─┬─┘ └───────────┬────────────────────────┘└──┬──────┘└────────┬─────────────────┘└─┬─┘' + '\n' +
                    ' scheme         authority                      path             query             fragment' + '\n',

                exampleServerResponse:
                    'HTTP/1.1 200 OK' + '\n' +
                    'Date: Mon, 23 May 2005 22:38:34 GMT' + '\n' +
                    'Content-Type: text/html; charset=UTF-8' + '\n' +
                    'Content-Length: 138' + '\n' +
                    'Last-Modified: Wed, 08 Jan 2003 23:11:55 GMT' + '\n' +
                    'Server: Apache/1.3.3.7 (Unix) (Red-Hat/Linux)' + '\n' +
                    'ETag: "3f80f-1b6-3e1cb03b"' + '\n' +
                    'Accept-Ranges: bytes' + '\n' +
                    'Connection: close' + '\n' +
                    '' + '\n' +
                    '<html>' + '\n' +
                    '  <head>' + '\n' +
                    '    <title>An Example Page</title>' + '\n' +
                    '  </head>' + '\n' +
                    '  <body>' + '\n' +
                    '    <p>Hello World, this is a very simple HTML document.</p>' + '\n' +
                    '  </body>' + '\n' +
                    '</html>' + '\n',
            };
        }
    }
